@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./assets/PixelMplus12-Regular.ttf) format('truetype');
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media, _:future, :root .safari_only {
      padding-bottom: 50px; 
  }
}

@media (max-width: 700px) {

  .topbar {
  position: sticky;
  z-index: 100;
  color: white;
  font-size: 2rem;
  top: 0;
  left: 0;
  right: 0;
  /* width: 200px; */
  /* height: 100%; */
  overflow: hidden;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.topbarright {
  padding-top: 10px;
  float: right;
}

  .sidemenu {
    display: none;
}

main {
  padding-top: 100px;
}

.mainbody {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
}

.gallery {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.products {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: left;
  /* margin-top: 3rem; */
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}
}

@media (min-width: 700px) {
    .sidemenu {
    position: sticky;
    z-index: 100;
    color: white;
    font-size: 2rem;
    top: 0;
    left: 0;
    right: calc(100vw - 300px);
    width: 300px;
    /* height: 100%; */
    padding-left: 100px;
    padding-top: 100px;
    overflow: hidden;
  }

  .gallery {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }

  .mainbody {
    position: absolute;
    left: 300px;
    right: 0;
    width: calc(100vw - 300px);
    top: 0;
  }

  main {
    width: 50vw;
    padding-top: 120px;
  }

  .topbar {
    display: none;
  }

  .products {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    /* margin-top: 3rem; */
    /* animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s; */
  }

}

.wholepage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body {
  box-sizing: border-box;
  top: 0rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, */
    /* Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; */
  margin: 0;
  /* font-family: 'Noto Sans JP', serif; */
  line-height: 1.5;
  /* font-family: 'Sawarabi Gothic', sans-serif; */
  font-family: 'MyFont', monospace;
  /* height: 100%; */
  background-color: black;
  /* height: 220px; */
  color: inherit;
  overflow: hidden;
  position: absolute;
  width: 100%;
  overscroll-behavior-y: hidden;
  /* background-attachment: fixed; */
  /* background-image: linear-gradient(to bottom right, rgb(22,29,46), rgb(55,13,54)),
  linear-gradient(to top left, red, transparent),
  url('assets/test2.png') no-repeat center center fixed; */
  background: url('assets/2.png');
  background-image: url('assets/1.png');
  /* background: url('assets/test2.png'),
  linear-gradient(to bottom right, rgb(22,29,46), rgb(55,13,54)),
  linear-gradient(to top left, red, transparent); */
  background-size: 200vw 200vh;
  background-blend-mode: screen;
  animation-name: backgroundscroller;
  animation-duration: 300s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;  
  /* -webkit-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
-moz-backface-visibility:    hidden;
-ms-backface-visibility:     hidden;
  -webkit-transform-style: preserve-3d; */
  /* -webkit-transform: translate3d(0, 0, 0); */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  /* margin: 0; height: 100%; overflow: hidden */
  /* touch-action: none; */
  -webkit-perspective: 1000;
-webkit-backface-visibility: hidden;
-webkit-transform:translate3d(0,0,0);
font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: orange;
}

.noscroll {
  overflow: auto;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: url('assets/3.png');
  background-size: 200vw 200vh;
  background-blend-mode: color-burn;
  animation-name: backgroundscroller;
  animation-duration: 400s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;  
  /* -webkit-backface-visibility: hidden; */
  /* -webkit-transform: translate3d(0, 0, 0); */
  /* overflow: hidden; */
  -webkit-transform: translate3d(0, 0, 0)
  }

.backgroundscroll{
  /* background-color: rgb(50,50,50); */
  background: url('assets/background3.png');
  /* url('assets/old.png'); */
  /* background:
  linear-gradient(red, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, blue, transparent); */
  /* background-blend-mode: screen; */
  /* opacity: 50%; */
  /* mix-blend-mode: color-dodge; */
  /* height: 100%; */
  /* width: 100vw; */
  /* z-index: 2; */
  background-size: 100vw 100vh;
  /* opacity: 50%; */
  /* background-blend-mode: color-burn; */
  animation-name: backgroundscroller;
  animation-duration: 600s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;  
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0)
}

@keyframes backgroundscroller {
  from {
    background-position: 0% 0%;    
  }
  to {
    background-position: 500% 500%, 400% 400%, 300% 300%, 200% 200%, 100% 100%;    
  }
}

@-webkit-keyframes  backgroundscroller {
  from {
    background-position: 0% 0%;    
  }
  to {
    background-position: 500% 500%, 400% 400%, 300% 300%, 200% 200%, 100% 100%;    
  }
}

header {
  background: white;
  padding: 1rem 5vw;
  width: 100%;
  position: fixed;
  z-index: 100;
  font-size: 5rem;
  top: 0;
  /* opacity: 1; */
  border-bottom: 2px solid whitesmoke;
  -moz-box-shadow:    1px 1px 10px 3px rgba(0,0,0,0.1);
  -webkit-box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.1);
  box-shadow:         1px 1px 10px 3px rgba(0,0,0,0.1);
}

div {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  /* -webkit-transform-style: preserve-3d; */
/* -webkit-backface-visibility: hidden; */
}

button {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

img {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

p {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

h1 {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  margin: 0rem;
  display: inline;
}

h3 {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

h4 {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

.concept {
  text-align: -webkit-center;
  font-style: italic;
}
/* 
.languages {
  padding: 1rem 5vw;
  top: 13px;
  right:0;
  position: fixed;
  display: inline-block;
  color: white;
  z-index: 10000;
} */



.background{
  /* background-color: rgb(50,50,50); */
  background-image: linear-gradient(to bottom right, rgb(22,29,46), rgb(55,13,54)),
  linear-gradient(to top left, red, transparent);
  /* url('assets/old.png'); */
  /* mix-blend-mode: color-dodge; */
  /* height: 100vh;
  min-height: 100vh; */
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  overflow: auto;
  /* opacity: 50%; */
  /* mix-blend-mode: color-dodge; */
  /* height: 100%; */
  /* z-index: 2; */
}

.headercolor {
  /* background: white; */
  position: fixed;
  width: 100%;
  top: 0;
  height: 80px;
  left: 0;
  display:block;
  /* opacity: 1; */
  z-index: 1000;
  box-shadow: 0 0 5px 10px #555;

}

.bodyarea{
  position: fixed;
  width: 100%;
  top: 80px;
  bottom: 0px;
  left: 0;
  overflow: auto;
  height: 100%;
  /* display: block; */
}


.tickets {
  padding: 1rem 5vw;
  top: 0;
  left:20%;
  position: fixed;
  display: inline-block;
  z-index: 100;
}

.languagesbuttonunselect {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
}

.languagesbuttonselect {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
  text-decoration: white underline;
}

.infotext {
  text-align: -webkit-center;
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
}

header a {
  color: black;
  font-weight: 800;
  text-decoration: none;
}

.Link {
  text-decoration: none;
  color: white;
}

main {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center; */
  top: 0;
  margin: auto auto 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  min-height: 100vh;
  z-index: 100;
  color: white;
  text-align: justify;
}

.maincentre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  top: 0;
  margin: auto auto 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  height: 100vh;
  z-index: 100;
  color: white;
  text-align: justify;
}

.maintickets {
  color: white;
}

.mainvideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  padding-top: 0;
  left: 200px;

  /* margin: auto auto 0rem; */
  /* padding: 1rem; */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  /* max-width: 80vw; */
  /* top: 80px; */
  /* width: 100vw; */
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  text-align: center;
  height: 100vh;
  /* min-height: 120vh; */
  max-width: 80vh;
  /* min-width: 200px; */
  z-index: 100;
  color: white;
}

.buytickets {
  min-width: 150px;
}

.buytickets:hover {
  min-width: 150px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}

/* #edit-img:hover img {
  -webkit-filter: invert(100%);
  filter: invert(100%)
} */

.textpage {
  color: white;
  padding: 3rem;
  background-color: rgba(0,0,0,0.6);
  box-shadow: 0 0 50px 100px rgba(0,0,0,0.6);  
}

.success {
text-align: center;
}

.cartwrapper {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  text-align: center;
}

.cartsummary {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  align-items: baseline;

  /* gap: 0.5rem; */
  /* grid-auto-rows: 200px; */
  align-items: baseline;
  /* gap: 0.5rem; */
  /* grid-auto-rows: 200px; */
  /* text-align: center; */
  /* grid-template-columns: 1fr; */
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;

  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

.cartsummaryempty {
  display: grid;
  gap: 2rem;
  font-style: italic;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

.cartsummarybuttons {
  display: grid;
  gap: 0rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100px;
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  /* padding-left: 5rem;
  padding-right: 5rem; */
}

.cartsummarytotal{
  display: grid;
  text-align: center;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  font-weight: bold;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

.buttonwrapper {
  text-align: center;
}

.buttonwrapperpurchase:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}

.buttonwrapperclose:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}

.buttonwrapperpurchase {
  font-family: 'MyFont', monospace;
  font-size: 1.2rem;
  text-align: center;
  background:
  linear-gradient(rgb(174, 0, 255), transparent),
  linear-gradient(to top left, rgb(255, 0, 98), transparent),
  linear-gradient(to top right, rgb(0, 140, 255), transparent);
  cursor: pointer;
  color: white;
  width: 200px;
  min-height: 30px;
}

.buttonwrapperclose {
  font-family: 'MyFont', monospace;
  font-size: 1.2rem;
  text-align: center;
  background: linear-gradient(to bottom right, rgb(22,29,46), rgb(55,13,54)),
  linear-gradient(to top left, red, transparent);
  cursor: pointer;
  color: white;
  width: 200px;
  min-height: 30px;
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
}

.buttonwrapperbuytickets {
  font-family: 'MyFont', monospace;
  font-size: clamp(0.5rem, 10vw, 1.5rem);
  text-align: center;
  padding: clamp(5px, 3vw, 15px);
  /* background-color: rgb(255,240,200); */
  /* background-blend-mode: color-burn; */
  /* background:
  linear-gradient(red, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, blue, transparent); */
  background:
  linear-gradient(rgb(174, 0, 255), transparent),
  linear-gradient(to top left, rgb(255, 0, 98), transparent),
  linear-gradient(to top right, rgb(0, 140, 255), transparent);
  /* box-shadow: orange; */
  cursor: pointer;
  color: white;
  width: 250px;
  max-width: 80vw;
  /* height: 50px; */
}

.buttonwrapperinfo {
  font-family: 'MyFont', monospace;
  font-size: clamp(0.5rem, 10vw, 1.5rem);
  text-align: center;
  padding: clamp(5px, 3vw, 15px);
  /* background-color: rgb(255,240,200); */
  /* background-blend-mode: color-burn; */
  /* background:
  linear-gradient(red, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, blue, transparent); */
  background:
  linear-gradient(purple, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, purple, grey);
  mix-blend-mode: color-burn;
  /* box-shadow: orange; */
  cursor: pointer;
  color: white;
  width: 250px;
  max-width: 80vw;
  /* height: 50px; */
}

.buttonwrapperinfo:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}

.buttonwrapperbuytickets:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}

.footer {
  color: rgba(211, 158, 44, 1);
  font-size: calc(0.8em + 0.5vw);

    /* background-color: black; */
  text-align: center;
  padding: 1rem;
  /* position: relative;
  bottom: 0rem; */
  height: 50px;
}

.earlybirdticket{
  color: rgba(211, 158, 44, 1);
  font-size: calc(1.8em + 0.5vw);
    /* background-color: black; */
  text-align: center;
  /* position: relative;
  bottom: 0rem; */
}

footer a {
  color: inherit;
}

.price {
  font-style: italic;
}

.producttext {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 1rem;
}

.productarrow {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  height: 100%;
}


.product img {
  max-width: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.product {
  align-items: baseline;
  gap: 1rem;
  display: grid;
  /* gap: 0.5rem; */
  /* grid-auto-rows: 200px; */
  align-items: baseline;
    display: grid;
    /* gap: 0.5rem; */
    grid-template-columns: 3fr 1fr;
    /* grid-auto-rows: 200px; */
    /* text-align: center; */
    display: grid;
    /* grid-template-columns: 1fr; */
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}

.product form {
  align-items: baseline;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 70px 50px 1fr;
}

.product input {
  border: 1px solid navy;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  padding: 0.25rem;
}

button {
  /* background: white; */
  border-radius: 0.5rem;
  /* color: black; */
  font-size: 1rem;
  /* font-weight: 500; */
  /* line-height: 1.25rem; */
  padding: 0.25rem;
  margin: 0.5rem;
  /* display: inline; */
  font-weight: 600;
}

h1 {
  /* font-size: clamp(2rem, 10vw, 2rem); */
}

.menutext {
  font-size: clamp(0.5rem, 2vh, 5rem);
  /* display: inherit; */
}

h2 {
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  color: whitesmoke;
  /* font-size: clamp(1.3rem, 5vw, 2rem); */
}

h3 {
  /* font-size: clamp(1rem, 3vw, 1.5rem); */
  margin: 0px;
}

.productbutton {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: rgba(255,255,255,0.8);
  /* animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s; */
  height: 20px;
  outline: none;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
}

.arrow {
  cursor: pointer;
}

.disabledarrow {
  cursor: pointer;
  opacity: 0.3;
}

.languagebutton {
  all: unset;
}

.mainaftertext {
  padding-top: 0;
  min-height: 1vh;
}

.underline {
  /* text-decoration: underline !important; 
  text-decoration-line: underline !important;
  -webkit-text-decoration-line: underline !important; */
  border-bottom: 3px solid white;
}

.videoTag {
  max-width: 90vw;
  z-index: 10000000;
}

.map-container {
  /* position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
  height: 50vh;
}

.mapbox-logo{
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

#marker {
  /* background-image: url('https://www.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg'); */
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* opacity: 0; */
  cursor: pointer;
}

.marker {
  /* background-image: url('https://www.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg'); */
  background: purple;
  border: 3px solid red;
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
  color: white;
  font-family: 'MyFont';
  text-align: center;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  background: purple;
}

.mapboxgl-ctrl-bottom-left {
  /* display: none; */
  visibility: hidden;
}

.mapboxgl-ctrl-logo {
  display: none;
}

.mapboxgl-ctrl-scale {
  font-family: 'MyFont', monospace;
visibility:visible;
position: relative;
top: 30px;
color: white;
background: purple;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.euro {
  font-family: 'VT323', monospace;
  display: inline;
  font-size: 2.3rem;
}

.center {
  text-align: center;
}

.popup {
  position: absolute;
  margin: auto;
  max-width: 1200px;
  max-height: 900px;
  color: white;
  padding: 1rem;
  top: 0;
  bottom: 0;
  z-index: 10000000000;
  overflow: auto;
  left: 0;
  right: 0;
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
  /* display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center; */
  top: 0;
  background: darkmagenta;
  border: white 1px solid;
}

.closeright {
  /* right: 0; */
  /* top: 0; */
  align-content: right;
  position: fixed;
  padding-top: 20px;
  /* padding-right: 30px; */
  cursor: pointer;
  padding-left: clamp(1px, 35vw, 550px);
}

.languageleft {
  /* left: 0;
  top: 0; */
  position: fixed;
  padding-top: 5px;
  padding-left: 30px;
  cursor: pointer;
}

.flyer {
  /* max-width: 600px; */
  /* width: 90vw; */
  max-width: 90vw;
  max-height: 80vh;
}

.galleryimg {
  /* max-height: 400px; */
}